.AboutUS {
  .header {
    background: url("../../Assets/Images/bannerImg.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    .appName {
      font-size: 45px;
      font-weight: 600;
      color: #fff;
    }
  }
  .content {
    background: url("../../Assets/Images/devdes.png") no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    padding: 60px 30px 50px 30px;
    .left {
      display: flex;
      // flex-direction;
      flex-wrap: wrap;
      gap: 30px;
      width: 100%;
      .item {
        margin-top: 70px;
        display: flex;
        justify-content: center;
        .iconContainer {
          svg#_x32_ {
            transform: rotate(-45deg);
          }
          svg#_x33_ {
            transform: rotate(-45deg);
          }
          transform: rotate(45deg);
          position: absolute;
          background: var(--primary-orange);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          margin-top: -130px;
          box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
        }
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(0, 0, 0, 0.36747198879551823) 35%,
          rgba(200, 200, 200, 1) 86%,
          rgba(255, 255, 255, 1) 100%
        );
        width: 46%;
        padding: 80px 20px 20px 20px;
        box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
        font-size: 22px;
        font-weight: 500;
        border-radius: 25px;
        color: var(--primary-dark);
        border-bottom: 15px solid var(--primary-dark);
        border-top: 1px solid var(--primary-orange);
        border-right: 1px solid var(--primary-orange);
        border-left: 1px solid var(--primary-orange);
        cursor: pointer;
        transition: 0.7s;
      }
      .item:hover {
        background: var(--primary-orange);
        background-size: 100% 100%;
        color: #fff;
      }
    }
  }
}

@media (max-width: 1480px) {
  .AboutUS .content .left .item {
    width: 44%;
  }
}

@media (max-width: 1010px) {
  .AboutUS .content .left .item {
    width: 100%;
  }
}
