@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #d2691e;
  --primary-yellow: #ab9c54;
  /* --primary-dark: rgb(48, 48, 46); */
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0, 7);
  --primary-blue-light: #191970;
  --primary-green-light: #2e8b57;
  --primary-dark: #283238;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
