.products {
  .Header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background: url("../../Assets/Images/product1.jpg") no-repeat;
    background-position: center;
    background-size: contain;

    .productsHeader {
      font-size: 35px;
      font-weight: 500;
      padding: 20px;
      background: linear-gradient(
        90deg,
        rgba(46, 139, 87, 1) 15%,
        rgba(25, 25, 112, 0.8492647058823529) 40%
      );
      color: #fff;
      border-radius: 25px;
      box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
    }
  }

  .restorantSection {
    .websiteSection {
      display: flex;
      padding: 40px;
      .imgConatiner {
        width: 50%;
        height: 400px;
        cursor: pointer;
        img {
          box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
          border-radius: 15px;
        }
      }
      .imgConatiner:hover {
        opacity: 0.8;
      }
      .content {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 20px 0px 20px;
        gap: 25px;
        .title {
          font-size: 35px;
          font-weight: 500;
          color: var(--primary-blue-light);
          padding: 20px;
          background: var(--primary-white);
          box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
          border-radius: 15px;
        }

        .content {
          width: 80%;
          font-size: 18px;
          font-weight: 500;
          color: var(--primary-blue-light);
          padding: 20px;
          background: var(--primary-white);
          box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
          border-radius: 15px;
        }

        .btnContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          button.aboutBtn {
            width: 200px;
            height: 50px;
            font-size: 20px;
            font-weight: 500;
            background: var(--primary-dark);
            color: var(--primary-white);
          }
          button.aboutBtn:hover {
            border: none !important;
            opacity: 0.9 !important;
          }
        }

        .ContactUsBtn {
          width: 150px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--primary-white);
          color: var(--primary-blue-light);
          font-size: 18px;
          font-weight: 500;
          box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
          border-radius: 5px;
          cursor: pointer;
        }
        .ContactUsBtn:hover {
          color: var(--primary-white);
          background: var(--primary-blue-light);
        }
      }
    }
  }
}

.textUnderline {
  text-decoration: underline !important;
}
.qrCodeProduct {
  .headerImgContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .content {
    background: #283238;
    color: #fff;
    padding: 20px 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    .title {
      font-size: 25px;
    }
    .subTitile {
      font-size: 20px;
    }
    .des {
      font-size: 18px;
    }
  }
  .decriptionForApp {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 30px 10%;
    .title {
      font-size: 25px;
    }
    .DES {
      font-size: 18px;
    }
  }
  .DESBG {
    padding: 30px 10%;
    text-align: center;
    font-size: 22px;
    background: #f1f1f1;
  }
}

@media (max-width: 1250px) {
  .products .restorantSection .websiteSection {
    flex-direction: column;
    gap: 30px;
    padding: 60px;
  }

  .products .restorantSection .websiteSection .imgConatiner {
    width: 100%;
    padding: 0px !important;
  }
  .products .restorantSection .websiteSection .content {
    width: 100%;
  }
  .websiteSection:nth-child(2) {
    flex-direction: column-reverse !important;
  }
  .products .restorantSection .websiteSection .imgConatiner {
    height: 300px;
  }
}

@media (max-width: 450px) {
  .products .restorantSection .websiteSection {
    padding: 20px;
  }
  .products .restorantSection .websiteSection .content {
    padding: 0px !important;
  }
  .products .restorantSection .websiteSection .content .content {
    padding: 20px !important;
  }
  .products .restorantSection .websiteSection .imgConatiner {
    height: 250px;
  }
}
