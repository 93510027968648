// dark mode

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-dark) !important;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
// navbar
.nav-dark {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 0px 50px;
  background: var(--primary-dark) !important;
  position: sticky;
  z-index: 100;
  top: 0;
  right: 0;
  .navigationDark {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .nav-item {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;
      padding: 7px;
      border-top: 0.1875rem solid var(--primary-dark);
      cursor: pointer;
    }
    .nav-item:hover {
      border-top: 0.1875rem solid var(--primary-orange);
      opacity: 0.8;
    }
  }
}

.logo-contanier {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}
.modeContainer {
  cursor: pointer;
}

//light mode navbar
.nav-light {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 0px 50px;
  background: #c0c0c0 !important;
  box-shadow: 3px 0 10px 0 rgb(183 192 206 / 20%);
  position: sticky;
  z-index: 100;
  top: 0;
  right: 0;
  .naviigationLight {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .nav-item {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      color: #191970;
      padding: 7px;
      border-top: 0.1875rem solid #191970;
      cursor: pointer;
    }
    .nav-item:hover {
      border-top: 0.1875rem solid #2e8b57;
      opacity: 0.8;
    }
  }
}
.active {
  border-top: 0.1875rem solid var(--primary-orange) !important;
  color: (--primary-orange) !important;
}

// menu for mobile
.hamburger-contanier {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  background: #fff;

  svg {
    fill: #d2691e !important;
  }
}

.hamburger-contanier-light {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--primary-dark);
}

.mobile-icon-container {
  display: flex;
  gap: 30px;
  align-items: center;
}

// footer
/*  Footer    */

.footer {
  background: var(--primary-dark);
  color: var(--primary-white);
  padding: 3rem;
  display: flex;
  gap: 20px;
  .contanier {
    width: 33%;
    align-items: flex-start;
  }
  .contanierItem {
    width: 33%;
    align-items: flex-start;
    display: flex;
    gap: 10px;
    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
    }
  }
}

.footer-light {
  background: #c0c0c0;
  color: var(--primary-blue-light);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-white);
  font-size: 18px;
}

.footer-light a {
  text-decoration: none;
  color: var(--primary-blue-light);
  font-size: 18px;
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
}

.footer-light a:hover {
  text-decoration: none;
  color: var(--primary-green-light);
}

.footer p {
  margin-bottom: 0;
}

.loadingContainer {
  position: fixed;
  top: 100;
  left: 0;
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 100000;
}

// responsive
@media (max-width: 1050px) {
  .logo-contanier {
    width: 130px;
  }
}

@media (max-width: 980px) {
  .logo-contanier {
    width: 200px !important;
  }
  .dark-drawer {
    margin-top: 10px;
    .ant-drawer-header {
      background: var(--primary-dark) !important;
      .ant-drawer-title {
        color: #fff !important;
        font-size: 26px;
        display: flex;
        justify-content: center;
      }
      button.ant-drawer-close {
        color: #fff !important;
        font-size: 26px;
      }
    }
    .ant-drawer-body {
      padding: 0px !important;
      background: var(--primary-dark);
      .navigationDark {
        .nav-item {
          height: 50px;
          font-size: 22px;
          display: flex;
          color: #fff;
          align-items: center;
          justify-content: center;
        }
        .active {
          color: var(--primary-orange) !important;
        }
      }
    }
  }

  .light-drawer {
    .ant-drawer-header {
      background: #c0c0c0 !important;
      .ant-drawer-title {
        color: #191970 !important;
        font-size: 26px;
        display: flex;
        justify-content: center;
      }
      button.ant-drawer-close {
        color: #191970 !important;
        font-size: 26px;
      }
    }
    .ant-drawer-body {
      padding: 0px !important;
      background: #c0c0c0;
      .naviigationLight {
        margin-top: 10px;
        .nav-item {
          color: #191970 !important;
          font-size: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .active {
          border-top: 0.1875rem solid var(--primary-orange) !important;
          color: (--primary-orange) !important;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .nav-dark {
    padding: 0px 20px 0px 20px !important;
  }

  .hamburger-contanier {
    width: 32px;
    height: 32px;
    padding: 0px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
