// Landing Section

.homePage {
  .firstSection {
    background: #f1f1f1;
    height: calc(100vh - 100px);
    .background {
      // background: url("../../Assets/Images/SectionImg.png") no-repeat;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: contain !important;
      height: -webkit-fill-available !important;
      width: 100%;
      position: absolute;
      display: flex;
      .descriptionSection {
        width: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        margin-left: 3%;
        .item {
          display: flex;
          flex-direction: column;
          background: var(--primary-white);
          box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
          border-radius: 50%;
          width: 300px;
          height: 300px;
          align-items: center;
          justify-content: center;
          .it {
            font-size: 50px;
            font-weight: 600;
          }
          .services {
            font-size: 40px;
            font-weight: 500;
            color: var(--primary-orange);
          }
        }
      }
      .descriptionSecond {
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        margin-right: 2%;

        .item {
          display: flex;
          flex-direction: column;
          background: var(--primary-white);
          box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
          border-radius: 50%;
          width: 300px;
          height: 300px;
          align-items: center;
          justify-content: center;
          .it {
            font-size: 40px;
            font-weight: 500;
            color: var(--primary-orange);
          }
          .services {
            font-size: 50px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

// AboutUs Section
.aboutSection {
  width: 100%;
  height: 450px;
  display: flex;
  .rondConteiner {
    position: absolute;
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      border-radius: 50%;
      height: 420px;
      width: 420px;
      gap: 20px;
      background: var(--primary-orange);
      box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        color: var(--primary-white);
        font-size: 60px;
        font-weight: 500;
      }
      .des {
        font-size: 19px;
        text-align: center;
        color: var(--primary-white);
        font-weight: 500;
      }
    }
  }
  .leftSider {
    background: var(--primary-dark);
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-left: 3%;
    gap: 20px;
    .title {
      color: var(--primary-white);
      font-size: 60px;
      font-weight: 500;
    }
    button.ant-btn {
      width: 120px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      color: var(--primary-white);
      background: var(--primary-dark-grey);
      border: none;
    }
  }
  .rightSider {
    flex-direction: column;
    background: var(--primary-dark-grey);
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;
    padding-right: 3%;
    .title {
      color: var(--primary-white);
      font-size: 60px;
      font-weight: 500;
      text-align: end;
    }
    .buttonCon {
      button.ant-btn {
        font-size: 16px;
        font-weight: 500;
        background: var(--primary-dark);
        width: 200px;
        height: 50px;
        border-radius: 5px;
        border: none;
        color: var(--primary-white);
      }
    }
    .buttonCon:hover {
      opacity: 0.9;
    }
  }
  .buttonConLeft {
    button.ant-btn {
      font-size: 16px;
      font-weight: 500;
      background: var(--primary-dark-grey);
      width: 200px;
      height: 50px;
      border-radius: 5px;
      border: none;
      color: var(--primary-white);
    }
  }
  .buttonConLeft:hover {
    opacity: 0.9;
  }
}

// Welcome Section
.welcome-section {
  background: var(--primary-gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px 20% 40px 20%;
  .welcome {
    font-size: 35px;
    font-weight: 600;
    color: var(--primary-dark);
    span {
      color: var(--primary-orange);
    }
  }
  .item {
    font-size: 15px;
    color: var(--primary-orange);
  }
  .description {
    font-size: 18px;
    font-weight: 400;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    button.aboutBtn {
      width: 200px;
      height: 50px;
      font-size: 20px;
      font-weight: 500;
      background: var(--primary-dark);
      color: var(--primary-white);
    }
    button.aboutBtn:hover {
      border: none !important;
      opacity: 0.9 !important;
    }
  }
}

// Services Section
.cardSection {
  background: url("../../Assets/Images/sectionDes.jpg") no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  display: flex;
  padding: 40px 0px 40px 0px;
  img {
    object-fit: cover;
  }
  .firstContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px;
    .item {
      width: 100%;
      background-color: var(--primary-dark);
      border-radius: 10px;
      padding: 10px 0px 10px 0px;
      color: var(--primary-white);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      .title {
        font-weight: 500;
        font-size: 25px;
      }
      .des {
        padding: 0px 20px 0px 20px;
      }
      .line {
        border-bottom: 2px solid var(--primary-orange);
        width: 15%;
        height: 7px;
        margin-bottom: 10px;
        transition: 0.7s;
      }
    }
    .item:hover {
      .line {
        width: 66%;
      }
    }
  }
  .secondContainer {
    width: 50%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding: 20px;
    .item {
      color: var(--primary-white);
      border-radius: 10px;
      background-color: var(--primary-dark);
      width: 100%;
      padding: 10px 0px 10px 0px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      .title {
        font-size: 25px;
        font-weight: 500;
      }
      .des {
        padding: 0px 20px 0px 20px;
      }
      .line {
        border-bottom: 2px solid var(--primary-orange);
        width: 15%;
        margin-bottom: 10px;
        height: 7px;
        transition: 0.7s;
      }
    }
    .item:hover {
      .line {
        width: 66%;
      }
    }
  }
}

// product section stepper
.stepper {
  display: flex;
  .steepDiv {
    display: flex;
    align-items: center;
    padding: 50px;
    width: 25% !important;
  }
  .stepperContent {
    width: 25%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-dark);
    .content {
      font-weight: 500;
      font-size: 20px;
      background: var(--primary-light-grey);
      padding: 20px;
      border-radius: 25px;
      border-left: 12px solid var(--primary-orange);
    }
  }
  .imgDiv {
    width: 50%;
    background: url("../../Assets/Images/4380747.jpg") no-repeat;
    background-position: center;
    background-size: contain;
    object-fit: cover;
  }
  .ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: var(--primary-dark) !important;
    border-color: var(--primary-dark) !important;
  }
  .ant-progress
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: var(--primary-dark) !important;
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: var(--primary-dark);
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: var(--primary-gray) !important;
    border-color: var(--primary-green-light) !important;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--primary-green-light) !important;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-title {
    color: var(--primary-green-light) !important;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon {
    border-color: var(--primary-green-light) !important;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: var(--primary-green-light) !important;
  }
}

@media (max-width: 1050px) {
  .contacts .contactForm .formCon {
    padding: 20px;
  }
}

@media (max-width: 950px) {
  .homePage .firstSection .background {
    flex-direction: column !important;
    gap: 100px !important;
  }

  .homePage .firstSection .background .descriptionSection {
    width: 100% !important;
    margin: 0px !important;
    .item {
      margin: 2%;
    }
  }

  .homePage .firstSection .background .descriptionSecond {
    width: 100% !important;
    .item {
      margin: 2%;
    }
  }

  .aboutSection .rondConteiner {
    height: 100%;
  }

  .aboutSection {
    flex-direction: column;
    height: unset;
    .leftSider {
      height: 380px;
      width: 100% !important;
      padding: 20px 0px 20px 0px !important;
      margin: 0px;
      align-items: center !important;
      justify-content: flex-start !important;
      .title {
        br {
          display: none !important;
        }
      }
    }
    .rightSider {
      margin: 0px;
      padding: 20px 0px 20px 0px !important;
      width: 100% !important;
      justify-content: flex-end !important;
      align-items: center !important;
      height: 380px;
      .title {
        br {
          display: none !important;
        }
      }
    }
  }

  .contacts {
    .HeaderContact {
      display: grid !important;
      grid-template-columns: 50% 50%;
      justify-items: center;
      height: 100% !important;
      background: var(--primary-grey) !important;
      gap: 0px !important;
      .item {
        margin: 50px 0px 0px 0px !important;
      }
    }

    .contactForm {
      flex-direction: column !important;
      margin-top: -1px !important;
      padding-top: 0px !important;

      .descriptionCon {
        width: fit-content !important;
      }
      .formCon {
        width: 100% !important;
        padding: 0px 0px 40px 0px !important;
      }
      .inputContainer {
        width: 100% !important;
      }
    }
  }
  .footer {
    padding: 1.5rem !important;
    flex-direction: column;
  }

  .footer .contanierItem {
    width: 100%;

    .row {
      flex-direction: row;
    }
  }

  .footer .contanier {
    width: 100% !important;
    display: flex;
    align-items: center;
    gap: 20px;
    p {
      margin: 0px !important;
    }
  }
}

@media (max-width: 700px) {
  .cardSection {
    flex-direction: column;
    padding: 40px !important;
    gap: 30px !important;
    .firstContainer {
      width: 100%;
      margin: 0px;
      padding: 0px !important;
    }
    .secondContainer {
      padding: 0px !important;
      width: 100%;
    }
  }
  .stepper {
    flex-direction: column;
    .steepDiv {
      width: 100% !important;
      justify-content: center;
      display: flex;
      padding: 30px 0px 30px 0px !important;
    }
    .stepperContent {
      width: fit-content !important;
    }
  }
  .stepper .imgDiv {
    width: unset;
    height: 300px !important;
  }
  .ant-steps.ant-steps-vertical {
    align-items: baseline !important;
    margin-left: 25% !important;
  }
}

@media (max-width: 620px) {
  form#contacts {
    padding: 0px 50px 30px 50px !important;
    width: 100% !important;
  }

  .contacts .contactForm .formCon button.contactBtn {
    width: 100% !important;
  }

  .footer .contanier {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer .contanierItem .row {
    flex-direction: column !important;
    width: max-content !important;
    margin-right: 30px !important;
  }
}

@media (max-width: 550px) {
  .homePage .firstSection {
    height: 600px !important;

    .background {
      height: 600px !important;
    }
  }
  .homePage .firstSection .background .descriptionSection {
    height: 300px !important;
  }
  .homePage .firstSection .background .descriptionSecond {
    height: 300px !important;
  }
  .contacts .HeaderContact {
    grid-template-columns: 100% !important;
    .item {
      width: 350px !important;
    }
  }
  .homePage .firstSection .background .descriptionSecond {
    justify-content: flex-end !important;

    .item {
      width: 150px;
      height: 150px;
      .it {
        font-size: 20px;
      }
      .services {
        font-size: 30px;
      }
    }
  }

  .homePage .firstSection .background .descriptionSection {
    justify-content: flex-start;

    .item {
      width: 150px;
      height: 150px;

      .it {
        font-size: 30px;
      }
      .services {
        font-size: 30px;
      }
    }
  }

  .ant-steps.ant-steps-vertical {
    margin-left: 20% !important;
  }

  .aboutSection .leftSider {
    height: 230px !important;

    .title {
      font-size: 30px !important;
    }
  }

  .aboutSection .rightSider {
    height: 230px !important;

    .title {
      font-size: 30px;
    }
  }

  .aboutSection .rondConteiner {
    height: 560px !important;
    .item {
      width: 250px !important;
      height: 250px !important;
      .title {
        font-size: 30px !important;
      }
      .des {
        font-size: 12px !important;
      }
    }
  }
  .welcome-section {
    padding: 40px 20px 40px 20px;
  }
  .ant-steps.ant-steps-vertical {
    margin-left: 10% !important;
  }
  .welcome-section .welcome {
    font-size: 25px !important;
  }
  .welcome-section .description {
    font-size: 16px !important;
  }
  .contacts .contactForm .descriptionCon .des {
    font-size: 16px !important;
  }
  .stepper .stepperContent .content {
    font-size: 16px !important;
  }
}

@media (max-width: 400px) {
  .contacts .HeaderContact .item {
    width: 250px !important;
  }
}

@media (max-width: 391px) {
  .homePage .firstSection {
    height: 530px !important;

    .background {
      height: 530px !important;
    }
  }
  .homePage .firstSection .background .descriptionSection {
    height: 265px !important;
  }
  .homePage .firstSection .background .descriptionSecond {
    height: 265px !important;
  }
  .contacts .contactForm .descriptionCon .title {
    font-size: 30px !important;
  }
}
