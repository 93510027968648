.servicesPage {
  .headerServices {
    width: 100%;
  }
  .headerdEV {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    img.devHeader {
      position: absolute;
    }
    .content {
      z-index: 10;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: 20px;
      background: linear-gradient(
        90deg,
        rgba(171, 156, 84, 0.9332983193277311) 5%,
        rgba(244, 244, 244, 0.6559873949579832) 47%,
        rgba(171, 156, 84, 1) 95%
      );
      border-radius: 25px;
      box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      color: var(--primary-orange);
    }
    .des {
      font-size: 18px;
      font-weight: 500;
      color: var(--primary-dark);
    }
  }
  .itemDev {
    justify-content: center;
    gap: 30px;
    display: flex;
    background-position: center !important;
    flex-wrap: wrap;
    background: url("../../Assets/Images/webDevBG.png") no-repeat;
    background-size: cover;
    .itemContainer {
      width: 42%;
      padding: 40px;
      background: linear-gradient(
        90deg,
        rgba(204, 209, 217, 1) 13%,
        rgba(171, 156, 84, 0.9024859943977591) 76%
      );
      border-radius: 25px;
      box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
      font-size: 17px;
      font-weight: 500;
      border-bottom: 15px solid var(--primary-dark);
      border-top: 1px solid var(--primary-yellow);
      border-right: 1px solid var(--primary-yellow);
      border-left: 1px solid var(--primary-yellow);
    }
  }
  .itemWeb {
    position: sticky;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--primary-grey);
    .BG {
      background: var(--primary-dark);
    }
    .headerMobile {
      background-position: center !important;
      background: var(--primary-dark);
      background: url("../../Assets/Images/mobileServicesHeader-removebg-preview.png")
        no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 350px;
      .content {
        z-index: 10;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        padding: 20px;
        background: linear-gradient(
          90deg,
          rgba(46, 139, 87, 1) 15%,
          rgba(25, 25, 112, 0.8492647058823529) 40%
        );
        color: #fff;
        border-radius: 25px;
        box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
      }
      .title {
        font-size: 32px;
        font-weight: 500;
        color: var(--primary-yellow);
      }
      .des {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .itemMobile {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      background: url("../../Assets/Images/mobileBG.png") no-repeat;
      background-position: center;
      background-size: contain;
      .itemContainer {
        width: 42%;
        padding: 40px;
        background: linear-gradient(
          90deg,
          rgba(46, 139, 87, 1) 15%,
          rgba(25, 25, 112, 0.8492647058823529) 40%
        );
        color: #fff;
        border-radius: 25px;
        box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
        font-size: 17px;
        font-weight: 500;
        border-bottom: 15px solid var(--primary-dark);
        border-top: 1px solid var(--primary-dark);
        border-right: 1px solid var(--primary-dark);
        border-left: 1px solid var(--primary-dark);
      }
    }
  }
  .item {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.BGSoftware {
  background: #fff;
  height: 350px;
  width: 100%;
  .headerMobile {
    margin-top: -18px;
    height: 350px;
    background-position: center !important;
    background: url("../../Assets/Images/software_text_1.jpg") no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.contentSoftwareDev {
  height: 100%;
  background: url("../../Assets/Images/learningcoding.jpg") no-repeat;
  background-size: contain;
  background-position: center;
  .itemMobile {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    .itemContainer {
      width: 45%;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: 20px;
      background: linear-gradient(
        90deg,
        rgba(204, 209, 217, 1) 13%,
        rgba(171, 156, 84, 0.9024859943977591) 76%
      );
      border-bottom: 15px solid var(--primary-dark);
      border-top: 1px solid var(--primary-yellow);
      border-right: 1px solid var(--primary-yellow);
      border-left: 1px solid var(--primary-yellow);
      border-radius: 25px;
      box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      color: var(--primary-dark);
    }
    .des {
      font-size: 18px;
      font-weight: 500;
      color: var(--primary-dark);
    }
  }
}

.BG {
  background: var(--primary-dark);
}

.headerConsulting {
  background-position: center !important;
  background: var(--primary-dark);
  background: url("../../Assets/Images/site_support_12-removebg-preview.png")
    no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  .content {
    z-index: 10;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background: linear-gradient(
      90deg,
      rgba(46, 139, 87, 1) 15%,
      rgba(25, 25, 112, 0.8492647058823529) 40%
    );
    color: #fff;
    border-radius: 25px;
    box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
  }
  .title {
    font-size: 32px;
    font-weight: 500;
    color: var(--primary-yellow);
  }
  .des {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }
}

.itemConsulting {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  background: url("../../Assets/Images/IndoSeptember93-removebg-preview.png")
    no-repeat;
  background-position: center;
  background-size: contain;
  .itemContainer {
    width: 42%;
    padding: 40px;
    background: linear-gradient(
      90deg,
      rgba(46, 139, 87, 1) 15%,
      rgba(25, 25, 112, 0.8492647058823529) 40%
    );
    color: #fff;
    border-radius: 25px;
    box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
    font-size: 17px;
    font-weight: 500;
    border-bottom: 15px solid var(--primary-dark);
    border-top: 1px solid var(--primary-dark);
    border-right: 1px solid var(--primary-dark);
    border-left: 1px solid var(--primary-dark);
  }
}

@media (max-width: 1290px) {
  .servicesPage .itemDev .itemContainer {
    width: 40%;
  }
  .servicesPage .itemWeb .itemMobile .itemContainer {
    width: 40%;
  }
  .itemConsulting .itemContainer {
    width: 40%;
  }
  .contentSoftwareDev .itemMobile .itemContainer {
    width: 40%;
  }
  .servicesPage .itemDev {
    background-size: contain !important;
  }
}

@media (max-width: 1050px) {
  .servicesPage .itemDev .itemContainer {
    width: 39%;
  }
  .servicesPage .itemWeb .itemMobile .itemContainer {
    width: 39%;
  }
  .itemConsulting .itemContainer {
    width: 39%;
  }
  .contentSoftwareDev .itemMobile .itemContainer {
    width: 39%;
  }
}

@media (max-width: 960px) {
  .servicesPage .itemDev .itemContainer {
    width: 95%;
  }
  .servicesPage .itemWeb .itemMobile .itemContainer {
    width: 95%;
  }
  .itemConsulting .itemContainer {
    width: 95%;
  }
  .contentSoftwareDev .itemMobile .itemContainer {
    width: 95%;
  }
}

@media (max-width: 960px) {
  .servicesPage .headerdEV .content {
    width: 80%;

    .des {
      font-size: 16px;
    }
  }

  .servicesPage .itemWeb .headerMobile .content {
    width: 80% !important;

    .title {
      font-size: 25px;
    }

    .des {
      font-size: 16px !important;
    }
  }

  .headerConsulting .content {
    width: 80% !important;

    .des {
      font-size: 16px !important;
    }
  }
}
