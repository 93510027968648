.contacts {
  .HeaderContact {
    background: url("../../Assets/Images/2202_w039_n003_115b_p1_115.jpg")
      no-repeat;
    height: 300px;
    background-position: center;
    background-size: contain;
    display: flex;
    gap: 40px;
    align-items: flex-end;
    justify-content: center;
    .item {
      margin-bottom: -140px;
      width: 250px;
      height: 200px;
      background: var(--primary-white);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      box-shadow: 0px 15px 35px rgba(26, 26, 26, 0.5);
      cursor: pointer;
      transform: 1s;
      transition-timing-function: linear;
      .title {
        font-size: 18px;
        font-weight: 500;
        color: var(--primary-yellow);
      }
    }
    .item:hover {
      transform: 1s;
      transition-timing-function: linear;
      svg {
        fill: #fff !important;
      }
      color: #fff;
      background: linear-gradient(
        90deg,
        rgba(46, 139, 87, 1) 15%,
        rgba(25, 25, 112, 0.8492647058823529) 40%
      );
    }
  }
  .contactForm {
    background-color: var(--primary-grey);
    display: flex;
    padding-top: 130px;
    .descriptionCon {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      padding: 10%;
      .title {
        font-size: 32px;
        font-weight: 500;
        color: var(--primary-yellow);
      }
      .des {
        font-size: 18px;
        font-weight: 400;
      }
    }
    .formCon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60%;
      .inputContainer {
        margin-bottom: 20px;
        width: 500px;
        .label {
          color: var(--primary-yellow);
          font-size: 16px;
          font-weight: 500;
        }
        .ant-input {
          background: transparent !important;
          border: none !important;
        }
        .ant-input:focus {
          box-shadow: none;
          border: none !important;
        }
        .ant-input:visited {
          box-shadow: none;
          border: none !important;
        }
        .line {
          border-bottom: 3px solid var(--primary-dark);
          transition: 1.2s;
          transition-timing-function: linear;
        }
        .lineActive {
          transition: 1.2s;
          border-bottom: 3px solid var(--primary-yellow);
          transition-timing-function: linear;
        }
      }
      button.contactBtn {
        width: 500px;
        height: 45px;
        background: transparent;
        border: 1px solid var(--primary-yellow);
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
      }
      button.contactBtn:hover {
        background: var(--primary-dark);
        border: 1px solid var(--primary-dark);
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: var(--primary-white);
      }
    }
    textarea {
      resize: none;
    }
  }
}

.ant-form-item {
  margin-bottom: 0px !important;
}

a {
  text-decoration: none !important;
  color: var(--primary-dark);
}
